<script>

//  criado a brach master 
import appConfig from "@/app.config";
import { http } from "../../../helpers/easyindustriaapi/config";
import { authEasyMethods, notificationMethods } from "@/state/helpers";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";

export default {
  page: {
    title: "Pre Compra",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: { Layout, PageHeader, Insert, List, Edit },
  data() {
    return {
      currentpre_compra: {},
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Cadastro",
          href: "/",
          active: true,
        },
        {
          text: "Produto Item",
          href: "/Cadastro/item",
          active: true,
        },
      ],

      //Unidade: undefined,
      //pre_compra: [],
      grupos: undefined,
      pre_compra: [],
      pre_compra2: [],

      titleBody: "Pre Compra",
      editando: false,
      inserindo: false,
      showModal: false,
      tipos_produtct:[],
      fornecedores_pessoa:[],
      produtos2: [] 
    };
  },

  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa")); // para saber a emrpessa atual;
    // storage esta no navegador em aplucation;descricaogrupo
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isHide() {
      return !this.inserindo && !this.editando;
    },
  },
  mounted() {
    this.getData();
    this.getProducts_type();
    this.getFornecedores();
    this.getProdutos2();
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    async getProducts_type() {
      this.onLoader();

      try {
        let response = await http.get(
          "/tipoproduto?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.tipos_produtct = response.data;
         // console.log(this.tipos_produtct)
          //console.log(this.tipos_produtct)

          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.tipos_produtct = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    
    async getFornecedores() {
      this.onLoader();

      try {
        let response = await http.get(
          "/pessoa?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.fornecedores_pessoa = response.data;
          //console.log(this.fornecedores_pessoa)
          //console.log(this.fornecedores_pessoa)

          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.fornecedores_pessoa = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async getProdutos2() {
      this.onLoader();

      try {
        let response = await http.get(
          "/produto?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.produtos2 = response.data;
          //console.log(this.produtos2)
          //console.log(this.produtos2)

          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.produtos2 = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    
    async getData() {
      this.onLoader();

      try {
        let response = await http.get(
          "/pre-pedido?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.pre_compra = response.data;
          //console.log(this.pre_compra)

          setTimeout(() => {
            this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.pre_compra = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    setpre_compra(pre_compra) {
      this.currentpre_compra = pre_compra;
    },
    insert() {
      this.showModal = true;
      this.inserindo = !this.inserindo; // recebe true;
    },
    edit(oldpre_compra) {
      this.setpre_compra(oldpre_compra);
      this.editando = !this.editando;
    },

    async doPost(pre_pedido) {
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id

      pre_pedido.empresa_id = this.currentEmpresa.id;
      pre_pedido.user_id = this.currentUser.id;

      this.onLoader();

      try {
        let response = await http.post(
          "/pre-pedido?empresa_id=" + this.currentEmpresa.id,
          pre_pedido
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
          //  console.log("Sucesso");
            this.getData();
            this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },

    async doPut(pre_pedido) {
     // console.log(pre_pedido)
      this.getData();
      this.onLoader();
      this.getData();
      
      try {
        let response = await http.put(
          "/pre-pedido/" +
          pre_pedido.id +
          "?empresa_id=" +
          this.currentEmpresa.id,
          pre_pedido
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.makeToast("success", "Registro alterado");
            this.getData();
            this.editando = false;
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async doDelete(pre_pedido) {
     // console.log(pre_pedido);
      this.onLoader();

      try {
        let response = await http.delete(
          "/pre-pedido/" +
          pre_pedido.id +
          "?empresa_id=" +
          this.currentEmpresa.id,
          pre_pedido
        );
        if (response) {
          if (response.status === 200) {
            this.getData();
            this.offLoader();
            this.makeToast("warning", "Registro excluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    close_modal() { // fecha o modal ao clicar;
      this.showModal = false;
    },
    back() {
      //this.getData();
      this.inserindo = false;
      this.editando = false;
      this.titleBody = "Tipo pre_compra Preço";
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6"></div>
              <div class="col-sm-12 col-md-6 text-md-right">
                <button @click="insert()" v-if="isHide" type="button" class="btn btn-success">
                  + Incluir produto Item
                </button>
                <button @click="back()" v-if="!isHide" class="btn btn-secondary">
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div v-if="!isHide" class="card-body">
            <Edit v-if="editando" :empresa="currentEmpresa" :oldpre_compra="currentpre_compra" :listPrecompra="pre_compra"
              @newTitle="changeHomeTitle" @doPut="doPut" />
            <Insert v-if="inserindo" :listGrupos="pre_compra" :listGrupos2="pre_compra" :currentEmpresa="currentEmpresa"
              @newTitle="changeHomeTitle" @doPost="doPost" @back="back" :showModal="showModal"
              :tipos_produtos="tipos_produtct" :fornecedores="fornecedores_pessoa"
              :produtos2="produtos2" />
          </div>
          <!-- <b-modal
              v-model="showModal"
              id="modal-xl"
              size="xl"
              title="Tipo de Tabela de preços"
              hide-footer
            >
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_sigla_id" class="col-form-label">Nome</label>
                    <div class="col">
                      <input
                        v-model="pre_compra.ttp_nome"
                        class="form-control text-leftt"
                        type="text"
                        placeholder="digite a Sigla"
                        id="sigla"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-10">
                <button class="btn btn-light" @click="close_modal">Gravar</button>
              </div>
            </b-modal> -->


          <!-- Retirei isso ->  :listpre_compra="pre_compra" , para lista é so colocar de volta -->
          <List :listPreCompra="pre_compra" :currentEmpresa="currentEmpresa" :hide="isHide" @edit="edit" @newTitle="changeHomeTitle"
            @setpre_compra="setpre_compra" @doDelete="doDelete" />
        </div>
      </div>
    </div>
  </Layout>
</template>