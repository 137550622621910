<script>
import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";




export default {
  props: { 
     oldpre_compra: { type: Object, required: true },
     listPrecompra: { type: Array, required: true },
     /// parentSelected: { type: Object, required: true }
    },
  components: { 
    Multiselect 
    },
  data() {
    return {
      loader: {get: false},
      titleBody: 'Alterar Grupo',
      tabelas:{
        ttp_nome: null,
        
      },
      // items: [
      //   "Materia Prima (Rendimento em Produção)",
      //   "Produto Semi-Elaborado",
      //   "Materia Prima Comum",
      //   "Suprimento",
      //   "Produto Final",

      // ],
      items: [
        "pedido1",
        "pedido2",
        "pedido3",

      ],
      order: {},
      dateselected_order: null,
      dateselected_arrival: null,
      hide: false,
      amount: null,
      tipo_selecionado: null,
      supplier_name_selected: null,
      supplier_code_selected: null,
      note: null,
      value: null,
      Feedstock: null, // materia prima
      value_order: null, // valor_pedido
      situation1: null,
      situation2: null,
      Buyer: null, // comprador
      Comments: null,
      pre_ped:{
        tipo_id: 2, 
        fornecedor_id: 2,
        data: '2023/05/03',
        previsao_chegada:'2022/05/03',
        nnf: 'nota44',
        valor: 12,
        situacao_id: 1,
        comprador_id: 2,
        obervacoes: 'nada',                   
      },
      pre_pedido1:{}
    }
    
    
  },
  validations: {
    tabelas:{
        ttp_nome: {required}, 
      }
  },
  mounted() {
    this.setNewTitle();
    this.pre_pedido1 = this.oldpre_compra;
    this.teste= this.grupo
    console.log(this.pre_pedido1);
    this.note= this.oldpre_compra.nnf;
    this.value = this.oldpre_compra.valor
    this.dateselected_order = this.oldpre_compra.data
    this.dateselected_arrival = this.oldpre_compra.previsao_chegada
    this.supplier_code_selected = this.oldpre_compra.fornecedor_id
    this.supplier_name_selected = this.oldpre_compra.fornecedor_id + "- carlos"; // exemplo
    this.Comments = this.oldpre_compra.obervacoes
    this.situation1 = this.oldpre_compra.situacao_id
    
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();

      this.validatesFields()
        .then(() => {
          this.setPut();
        });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    
    setPut() {
      console.log('Gravou');
      this.pre_pedido1.nnf = this.note
      this.$emit('doPut', this.pre_pedido1);
    },
   
  },
    
}
</script>

<template>

<div class="card">
    
      <div class="form-row form-group" role="group">
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">Tipo</label>
            <div class="col">
              <multiselect v-model="tipo_selecionado" :options="items"></multiselect>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-2">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">Fornecedor</label>
            <div class="col" id="fornecedor_id">
              <input v-model="supplier_code_selected" type="text" class="form-control text-left" placeholder="codigo">
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <input v-model="supplier_name_selected" type="text" class="form-control text-left" placeholder="nome">
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Data do Pedido:</label>
                <b-form-datepicker id="data" v-model="dateselected_order"></b-form-datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Previsão de chegada :</label>
                <b-form-datepicker id="data_arrival" v-model="dateselected_arrival"></b-form-datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-2">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Nota Fiscal :</label>
                <input v-model="note" type="text" class="form-control text-left" placeholder="digite nota">
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-2">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Valor:</label>
                <input v-model="value" type="text" class="form-control text-left" placeholder="digite nota">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row form-group" role="group">

        <div class="col-sm-12 col-md-12">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">itens:</label>
                <div id="borda">
                  <button class="btn btn-light" id="btn1"  v-if="!hide"><i class="fas fa-edit"></i>
                    Adicionar Materia Prima</button>
                  <div v-if="hide">
                    <div class="form-row form-group" role="group">
                      <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <label for="data">Materia prima</label>
                              <multiselect v-model="Feedstock" :options="items">ola</multiselect>
                              <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <label for="data">Quantidade</label>
                              <input type="number" v-model="amount" @input="alter_value" class="form-control text-left">
                              <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <label for="data">valor</label>
                              <input v-model="value_order" disabled type="text" class="form-control text-left">
                              <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                          <div class="col">
                            <label for="data">&nbsp;</label>
                            <div>
                              <label for="data">&nbsp;</label>
                              <button class="btn btn-light" @click="oculta">Salvar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Situação:</label>
                <div class="form-check">
                  <input v-model="situation1" type="radio" name="classe" id="classe1"  checked="checked"
                    class="form-check-input">
                  <label for="classe1" class="form-check-label">Cosiderar que os produtos já foram recebidos</label>
                </div>
                <div class="form-check">
                  <input v-model="situation2" type="radio" name="classe" id="classe1"  checked="checked"
                    class="form-check-input">
                  <label for="classe1" class="form-check-label">Cosiderar que a compra ja está confirmada e
                    estabelecida</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Comprador:</label>
                <multiselect v-model="Buyer" :options="items"></multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row form-group" role="group">
        <div class="col-sm-12 col-md-12">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Observações :</label>
                <b-form-textarea v-model="Comments" id="texto"></b-form-textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="col-md-12 mt-10">
          <button class="btn btn-light" @click="setPut">Gravar</button>
        </div>
      </div>


    
    <!-- <div class="card-body">
                      <i
                        v-if="loader.get"
                        class="fa fa-spinner fa-5x fa-spin text-success text-center"
                      ></i>
                      <form id="form-tipo-movimento" role="form" class="form-horizontal">
                        <b-card no-body class="mb-1">
                          <b-card-body>
                            <h4>Tipo de Tabelas de Preço</h4>
                            <div class="form-row form-group" role="group">
                              <div class="col-sm-12 col-md-3">
                                <div class="form-group">
                                  <label for="tipo_sigla_id" class="col-form-label">Nome</label>
                                  <div class="col">
                                    <input
                                      v-model="tabelas.ttp_nome"
                                      class="form-control text-leftt"
                                      type="text"
                                      placeholder="digite a Sigla"
                                      id="sigla"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-card-body>
                        </b-card>
                      </form>
                    </div> -->
  </div>
</template>