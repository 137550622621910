<!-- resolver problea no get é so coloca um if para resolver o problema -->
<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { http } from "../../../helpers/easyindustriaapi/config";

import composicao from "./produto_composicao"

export default {
  //components: { Multiselect },
  props: {
    showModal: {
      type: Boolean,
    },
    tipos_produtos: { type: Array },
    fornecedores: { Array },
    produtos2: { Array }
  },
  components: {
    Multiselect,
    composicao
  },
  data() {
    return {
      loader: { get: false },
      titleBody: "Incluir Produto Item",
      tabelas: {
        ttp_nome: null,

      },
      items: [
        "pedido1",
        "pedido2",
        "pedido3",

      ],
      order: {},
      dateselected_order: null,
      dateselected_arrival: null,
      hide: false,
      amount: null,
      tipo_selecionado: null,
      supplier_name_selected: null,
      supplier_code_selected: null,
      note: null,
      value: null,
      Feedstock: null, // materia prima
      value_order: null, // valor_pedido
      situation1: null,
      situation2: null,
      Buyer: null, // comprador
      Comments: null,
      pre_ped: {
        tipo_id: 1,
        fornecedor_id: 1,
        data: '2023/05/03',
        previsao_chegada: '2022/05/03',
        nnf: 'nota',
        valor: 12,
        situacao_id: 1,
        comprador_id: 1,
        obervacoes: 'nada',
      },
      produtos: [],
      fornecedoresReceb: [],
      pesquisa_nome: '',
      nome: '',
      codigo: '',
      pesquisa_codigo: '',
      hide1: false,
      hide2: false,
      timer: null,
      list_products: [],
      produto_selecionado: null,
      seleciona_prod: null,

      valor_select_prod: null,
      modal2: false,
      nome_produto: '',
      // para pegar todas as composiçõa 
      // em um aarrya de objetos;
      list_composicoes: [],
      validate_composition: false,
      contador: 0

    };
  },

  validations() {
    return {
      tabelas: {
        ttp_nome: { required },
      },
    };
  },


  computed: {
    filterFornecedor() {
      //variavel que recebe o valor do input 
      const searchTerm = this.pesquisa_nome.toLowerCase();

      // filtra pelo codigo e pelo nome
      return this.fornecedoresReceb.filter(fornecedor =>
        fornecedor.pes_fantasia.toLowerCase().includes(searchTerm)
      );
    },
    // pesquisa por codigo
    filterFornecedorCodigo() {
      //variavel que recebe o valor do input 
      const searchTerm = this.pesquisa_codigo.toLowerCase();
      // filtra pelo codigo e pelo nome
      return this.fornecedoresReceb.filter(fornecedor =>
        fornecedor.pes_ie.toLowerCase().includes(searchTerm)
      );
    }
  },




  mounted() {
    this.setNewTitle();
    //this.pre_ped.tipo_id = this.

    this.produtos = this.tipos_produtos
    this.fornecedoresReceb = this.fornecedores;

    this.list_products = this.produtos2;/////////////

    // console.log(this.list_products)

  },

  methods: {
    //  logica para quando selecionar outro produto antes de ir para o model;
    validar_composittion2() {
      this.validate_composition = true;
    },
    // logica para aparece o botão de composicao da 
    // listagem de produto;
    validar_composittion() {
      this.validate_composition = !this.validate_composition;
    },
    Recebecodigo(codigo, id) {

      //console.log(id);
      const objetoEncontrado = this.fornecedoresReceb.find(objeto => objeto.id === id);

      // console.log(objetoEncontrado)
      this.codigo = codigo
      this.pesquisa_codigo = codigo;
      this.pesquisa_nome = objetoEncontrado.pes_fantasia
      this.nome = objetoEncontrado.pes_fantasia.pes_fantasia;
      // console.log(index)
    },


    //  busca a composicação pelo id do produto 
    // pois esse tabela tem um relacionamento com composição;  
    async composicao_produto(produto) {

      let response = await http.get('/composicao/pesquisa?id=' + produto.id)

      if (response.status === 200) {
        // alert("entrou");
        this.list_composicoes = response.data
        console.log(this.list_composicoes)

        if (this.validate_composition == false) {
          this.validar_composittion();
        }

      } else {
        console.log("naao entrou")
      }

    },

    RecebeNome(nome, id) {
      const objetoEncontrado = this.fornecedoresReceb.find(objeto => objeto.id === id);
      this.nome = nome
      this.pesquisa_nome = this.nome
      this.pesquisa_codigo = objetoEncontrado.pes_ie;
      this.coidgo = objetoEncontrado.pes_ie;
      //  console.log(this.nome);
    },

    // pega o produto da listagem de produtos;
    func_pegar(produto) {
      // usado para sumir o botão e so aparecer quando a requsição for respondida ;
      // que é a resição da função composicao_produto
      this.validate_composition = false;
      console.log(produto.id);
      this.valor_select_prod = produto;
      this.composicao_produto(produto)
    },
    mostrar_composicao() {
      // console.log(this.valor_select_prod);
      this.nome_produto = this.valor_select_prod.produto_nome
    },

    alter_value(value_order) {
      this.value_order = value_order.target.value;
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    oculta() {
      this.hide = !this.hide;
    },
    func_hide() {

      //this.hide1 =!this.hide1 

      setTimeout(() => {
        // Código que será executado após o atraso de 3 segundos
        if (this.pesquisa_codigo != '') {
          this.hide1 = true;
        } else {
          this.hide1 = false;
        }

        //console.log('Função atrasada executada');
      }, 2000);
    },

    func_hide2() {

      setTimeout(() => {
        // Código que será executado após o atraso de 3 segundos
        if (this.pesquisa_nome != '') {
          this.hide2 = true;
        } else {
          this.hide2 = false;
        }

        //console.log('Função atrasada executada');
      }, 2000);



    },
    // para o nome;
    func_hide3() {
      if (this.hide2) {
        this.hide2 = false
      }
    },
    // para codigo 
    func_hide4() {
      if (this.hide1) {
        this.hide1 = false
      }
    },
    setPost() {
      //this.tabelas
      this.$emit("doPost", this.pre_ped); // mudar isso aqui//
      // deve receber dos inputs;
      this.voltar();
    },
    voltar() {
      this.$emit("back");
    },
  },
};
</script>

<template>
  <div class="card">
    <b-modal v-model="showModal" id="modal-xl" size="xl" title="Cadastro Pedido de Compra" hide-footer @hidden="voltar()" >
      <div class="form-row form-group" role="group">
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">Tipo</label>
            <div class="col">
              <multiselect v-model="tipo_selecionado" :options="produtos" label="descricao"></multiselect>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-2">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">Fornecedor</label>
            <div class="col" id="fornecedor_id">
              <!--  @keyup.enter -->
              <input v-model="pesquisa_codigo" type="text" class="form-control text-left" placeholder="codigo"
                @input="func_hide">
              <ul v-if="hide1">
                <li v-for="(fornecedor, index) in filterFornecedorCodigo" :key="fornecedor.id">
                  <a href="#" @click="Recebecodigo(fornecedor.pes_ie, index = fornecedor.id), func_hide4()">{{
                    fornecedor.pes_ie }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <input v-model="pesquisa_nome" type="text" class="form-control text-left" placeholder="Pesquisar por nome"
                @input="func_hide2()">
              <ul v-if="hide2">
                <li v-for="(fornecedor, index) in filterFornecedor" :key="fornecedor.id">
                  <a href="#" @click="RecebeNome(fornecedor.pes_fantasia, index = fornecedor.id), func_hide3()">{{
                    fornecedor.pes_fantasia }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Data do Pedido:</label>
                <b-form-datepicker id="data" v-model="dateselected_order"
                  placeholder="selecionar Data"></b-form-datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Previsão de chegada :</label>
                <b-form-datepicker id="data_arrival" v-model="dateselected_arrival"
                  placeholder="selecionar Data"></b-form-datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-2">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Nota Fiscal :</label>
                <input v-model="note" type="text" class="form-control text-left" placeholder="digite nota">
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-2">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Valor:</label>
                <input v-model="value" type="text" class="form-control text-left" placeholder="digite nota">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row form-group" role="group">

        <div class="col-sm-12 col-md-12">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">itens:</label>
                <div id="borda">
                  <button class="btn btn-light" id="btn1" @click="oculta" v-if="!hide"><i class="fas fa-edit"></i>
                    Adicionar Materia Prima</button>
                  <div v-if="hide">
                    <div class="form-row form-group" role="group">
                      <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <!-- não tem materia prima  -->
                              <!-- aqui -->
                              <label for="data">Materia prima</label>
                              <multiselect v-model="Feedstock" :options="list_products" label="produto_materiaprima">ola
                              </multiselect>
                              <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <label for="data">Quantidade</label>
                              <input type="number" v-model="amount" @input="alter_value" class="form-control text-left">
                              <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                          <div class="col">
                            <div>
                              <label for="data">valor</label>
                              <input v-model="value_order" disabled type="text" class="form-control text-left">
                              <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                          <div class="col">
                            <label for="data">&nbsp;</label>
                            <div>
                              <label for="data">&nbsp;</label>
                              <button class="btn btn-light" @click="oculta">Salvar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">

            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Situação:</label>
                <div class="form-check">
                  <input v-model="situation1" type="radio" name="classe" id="classe1" value="P" checked="checked"
                    class="form-check-input">
                  <label for="classe1" class="form-check-label">Cosiderar que os produtos já foram recebidos</label>
                </div>
                <div class="form-check">
                  <input v-model="situation2" type="radio" name="classe" id="classe1" value="P" checked="checked"
                    class="form-check-input">
                  <label for="classe1" class="form-check-label">Cosiderar que a compra ja está confirmada e
                    estabelecida</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">&nbsp;</label>
            <div class="col">
              <div>
                <label for="data">Comprador:</label>
                <multiselect v-model="Buyer" :options="fornecedoresReceb" label="pes_fantasia"></multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="tipo_id" class="col-form-label">Listagem de Produtos</label>
            <div class="col">
              <multiselect v-model="produto_selecionado" :options="list_products" label="produto_nome"
                @select="func_pegar($event)" aria-placeholder="selecione">
              </multiselect>
              <div v-if="validate_composition">
                <button class="btn btn-light" @click="mostrar_composicao(), validar_composittion2()"
                  v-b-modal.modal-xl>Composição </button>
              </div>
              <div>
                <b-modal id="modal-xl" size="xl" :title="nome_produto" @hidden="validar_composittion()">
                  <composicao :composicoes="list_composicoes" :produto="valor_select_prod" />
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <!-- <label for="tipo_id" class="col-form-label">&nbsp;</label> -->
            <div class="col">
              <div>
                <label for="data">Observações :</label>
                <b-form-textarea v-model="Comments" id="texto"></b-form-textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row form-group" role="group">


      </div>
      <div class="card-body">
        <div class="col-md-12 mt-10">
          <button class="btn btn-light" @click="setPost">Gravar</button>
        </div>
      </div>


    </b-modal>
    <!-- <div class="card-body">
                      <i
                        v-if="loader.get"
                        class="fa fa-spinner fa-5x fa-spin text-success text-center"
                      ></i>
                      <form id="form-tipo-movimento" role="form" class="form-horizontal">
                        <b-card no-body class="mb-1">
                          <b-card-body>
                            <h4>Tipo de Tabelas de Preço</h4>
                            <div class="form-row form-group" role="group">
                              <div class="col-sm-12 col-md-3">
                                <div class="form-group">
                                  <label for="tipo_sigla_id" class="col-form-label">Nome</label>
                                  <div class="col">
                                    <input
                                      v-model="tabelas.ttp_nome"
                                      class="form-control text-leftt"
                                      type="text"
                                      placeholder="digite a Sigla"
                                      id="sigla"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-card-body>
                        </b-card>
                      </form>
                    </div> -->
  </div>
</template>

<style scoped>
.multiselect-wrapper {
  display: flex;
  align-items: center;
}

.multiselect-wrapper button {
  margin-left: 10px;
}

#borda {
  border: solid 2px black;
  border-radius: 12px;

}

#btn1 {
  margin-left: 40%;
}

.fa-edit {
  color: blue;
}
</style>